import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../layouts/main-layout";
import PrimaryButton from "../../../widgets/buttons/primary-button";
import "../../../../src/theme/spacing.scss";
import InputField from "../../../widgets/inputs/primary-input";
import styles from "../otp-verification.module.scss";
import FieldsValidation from "../../../validation/fields-validation";
import { otpCounter } from "../../../config/otp-config";
import DateUtils from "../../../utils/date";
import {
  civildIDetailsRenewalRoute,
  otpSuccessRenewalRoute,
} from "../../../routes/routes.const";
import { verifyOTPRenewal } from "../otp-verification.proxy";
import { alertMessagesMapping, availableMediumsEnum } from "../../../config";
import { useSelector, useDispatch } from "react-redux";
import {
  brokerageEums,
  creatingOrderServicesEnums,
  customerStatusEnums,
  entityEnums,
} from "../../../enums/general";
import {
  getLanguage,
  getSavedInternationalType,
  getSavedProduct,
} from "../../../utils";
import { setToken } from "../../../utils/auth";
import { useTranslation } from "react-i18next";
import Alert from "../../../components/alert-component-fixed";
import closeIcon from "../../../assets/icons/close-success-icon.svg";
import infoIcon from "../../../assets/icons/alert-success-info.svg";
import errorCloseIcon from "../../../assets/icons/alert-close-icon.svg";
import errorInfoIcon from "../../../assets/icons/alert-info-icon.svg";
import {
  getCustomer,
  postCustomerOrder,
  sendAndResendOTP,
  putLanguage,
  getCustomerStatus,
} from "../../../general-services.proxy";
import { locale } from "../../../localiztion";
import {
  setKycFields,
  setCountries,
} from "../../../store/slices/general.slice";
import { languageEnum } from "../../../enums/language-enum";
import { parseMessageWithUrls } from "../../../helpers/general-helpers";
import { customerFlowElligibleStatusEnums } from "../enums";

const OTPDetailsRenewal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const { civilIDNumber } = useSelector((state) => state.general.userInfo);
  const availableMediums = useSelector(
    (state) => state.general.availableMediums
  );
  const language = getLanguage();
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [resendOTPMessage, setResendOTPMessage] = useState("");
  const brokerageType = getSavedProduct();
  const internationalBrokerageType = getSavedInternationalType();
  const { kycFieldsAr, kycFieldsEn, countriesAr, countriesEn } = useSelector(
    (state) => state.general
  );
  let timer;
  const [remainingTime, setRemainingTime] = useState(otpCounter);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [OTPInfo, setOTPInfo] = useState({
    value: "",
    error: "",
  });
  const [isVerifyMaxAttempts, setIsVerifyMaxAttempts] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFieldDisabled, setIsFieldDisabled] = useState(!civilIDNumber);

  const customerVerifyOTPPayload = {
    civil_id_number: civilIDNumber,
    otp_code: OTPInfo.value,
  };

  const customerResendSendOTPPayload = {
    civil_id_number: civilIDNumber,
  };

  const updateRemainingTime = (prevTime) => {
    if (prevTime > 0) {
      return prevTime - 1;
    } else {
      setIsResendEnabled(true);
      clearInterval(timer);
    }
  };

  const startResendTimer = (val = false) => {
    setIsResendEnabled(false);
    if (val) {
      setRemainingTime(5 * otpCounter);
    } else {
      setRemainingTime(otpCounter);
    }
    clearInterval(timer);
    startInterval();
  };

  const startInterval = () => {
    timer = setInterval(() => {
      setRemainingTime((prevTime) => updateRemainingTime(prevTime));
    }, 1000);
  };

  const resendOTPCode = async () => {
    try {
      setOtpErrorMessage("");
      setResendOTPMessage("");
      setIsLoading(true);
      await sendAndResendOTP(customerResendSendOTPPayload);
      setResendOTPMessage("resendOTPMessage");
      if (isVerifyMaxAttempts) {
        startResendTimer(true);
      } else {
        startResendTimer();
      }
    } catch (exception) {
      setOtpErrorMessage(exception);
      const isMaxAttempts =
        exception.response.data.description === t("maxAttempts");
      startResendTimer(isMaxAttempts);
      throw new Error(exception);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendClick = async () => {
    setOTPInfo((prev) => ({
      ...prev,
      value: "",
      error: "",
    }));

    try {
      await resendOTPCode();
    } catch (exception) {
      console.error(exception);
    }
  };

  useEffect(() => {
    startInterval();

    return () => clearInterval(timer);
  }, []);

  const validateField = (value) => {
    setOtpErrorMessage("");
    setResendOTPMessage("");
    const fieldErrorObject = FieldsValidation.validateOTP(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setOTPInfo((prev) => ({
      ...prev,
      value,
      error: errorMessageReceived,
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  const isVerifyButtonDisabled =
    isLoading ||
    !OTPInfo.value ||
    OTPInfo.error ||
    !civilIDNumber ||
    isVerifyMaxAttempts;

  const getCustomerData = async () => {
    try {
      const customer = await getCustomer();
      return customer;
    } catch (exception) {
      throw exception;
    }
  };

  const fetchCustomerStatus = async () => {
    try {
      const { customerStatus } = await getCustomerStatus();
      return customerStatus;
    } catch (exception) {
      throw exception;
    }
  };

  const createOrder = async (customer) => {
    try {
      const payload = {
        bank_information: {
          bank_name: "",
          iban_number: "",
        },
      };

      const createCustomerOrderPayload = {
        services: [creatingOrderServicesEnums.KYC_RENEWAL],
        product_type: brokerageType,
        entity: entityEnums.NBK_KUWAIT,
        payload: payload,
      };

      await postCustomerOrder(createCustomerOrderPayload);
    } catch (exception) {
      throw new Error(exception);
    }
  };

  const updateLanguage = async () => {
    try {
      await putLanguage(language);
    } catch (exception) {
      console.error(exception);
    }
  };

  const checkIfCustomerIsElligible = (customerStatus) => {
    if (customerStatus === customerFlowElligibleStatusEnums.INACTIVE) {
      setOtpErrorMessage(locale.issueInCivilId2);
      return false;
    } else if (
      customerStatus === customerFlowElligibleStatusEnums.CURRENTLY_ONBOARDING
    ) {
      setOtpErrorMessage(locale.alreadyOnboarded);
      return false;
    }
    return true;
  };

  const handleVerify = async () => {
    try {
      setOtpErrorMessage("");
      setResendOTPMessage("");
      setIsLoading(true);
      setIsFieldDisabled(true);
      const { accessToken, customerId } = await verifyOTPRenewal(
        customerVerifyOTPPayload
      );
      if (!accessToken) {
        return;
      }
      setToken(accessToken);
      const customerStatus = await fetchCustomerStatus();
      const isElligibletoContinue = checkIfCustomerIsElligible(customerStatus);

      if (!isElligibletoContinue) {
        sessionStorage.clear();
        return;
      }
      let availableCustomer = null;

      availableCustomer = await getCustomerData();
      if (
        availableCustomer?.orderServices?.includes(
          creatingOrderServicesEnums.KYC_ONBOARDING
        ) &&
        availableCustomer.clientRequestStatus !== "COMPLETE"
      ) {
        setOtpErrorMessage(locale.alreadyOnboarded);
        sessionStorage.clear();
        return;
      }

      const isKYCOnboardingComplete =
        availableCustomer?.orderServices?.includes(
          creatingOrderServicesEnums.KYC_ONBOARDING
        ) && availableCustomer.clientRequestStatus === "COMPLETE";

      const isKYCRenewalComplete =
        availableCustomer?.orderServices?.includes(
          creatingOrderServicesEnums.KYC_RENEWAL
        ) && availableCustomer.clientRequestStatus === "COMPLETE";

      const isKYCUpdayeComplete =
        availableCustomer?.orderServices?.includes(
          creatingOrderServicesEnums.KYC_UPDATE
        ) && availableCustomer.clientRequestStatus === "COMPLETE";

      const isOnlyBrokerageService =
        availableCustomer?.orderServices?.includes(
          creatingOrderServicesEnums.BROKERAGE
        ) && availableCustomer?.orderServices?.length === 1;

      if (
        !availableCustomer.orderId ||
        isKYCOnboardingComplete ||
        isKYCRenewalComplete ||
        isOnlyBrokerageService ||
        isKYCUpdayeComplete
      ) {
        try {
          await createOrder(availableCustomer);
        } catch (exception) {
          console.error(exception);
        }
      }

      if (!availableCustomer.language) {
        await updateLanguage();
      } else {
        if (availableCustomer.language === languageEnum.arabic) {
          dispatch(setKycFields(kycFieldsAr));
          dispatch(setCountries(countriesAr));
        } else {
          dispatch(setKycFields(kycFieldsEn));
          dispatch(setCountries(countriesEn));
        }
      }

      navigate({
        pathname: otpSuccessRenewalRoute,
      });
    } catch (exception) {
      setOtpErrorMessage(exception);
      if (
        exception.response.data.description === locale.issueEncountered ||
        exception.response.data.description === locale.kycExpired ||
        exception.response.data.description === locale.alreadyOnboarded ||
        exception.response.data.description === locale.issueInCivilId2
      ) {
        sessionStorage.clear();
      }

      if (
        exception.response.data.description ===
        (locale.maxAttempts || locale.maxAttemptsArabic)
      ) {
        setIsVerifyMaxAttempts(true);
      }
      setTimeout(
        () => {
          setIsVerifyMaxAttempts(false);
        },
        5 * 60 * 1000
      );
      console.error(exception);
    } finally {
      setIsLoading(false);
      setIsFieldDisabled(false);
    }
  };
  const combinedText = `${t("resendCountTime")} ${
    remainingTime === otpCounter
      ? t("oneMinuteCounter")
      : DateUtils.formatCounterTime(remainingTime)
  }`;

  const renderFooter = () => (
    <div className={styles.otpFooterStyle}>
      <PrimaryButton
        onClick={handleVerify}
        disabled={isVerifyButtonDisabled}
        data-testid={"verifyOtp"}
      >
        {t("verifyLabel")}
      </PrimaryButton>

      <p className={`${styles.textStyle}`}>
        {!isResendEnabled ? (
          <div className={styles.disabledText}>{combinedText}</div>
        ) : (
          <div
            className={
              !civilIDNumber ? styles.disabledText : styles.enabledText
            }
            onClick={handleResendClick}
          >
            {t("resendOTP")}
          </div>
        )}
      </p>
    </div>
  );

  const getOtpErrorMessage = () => {
    if (!otpErrorMessage) {
      return;
    }

    const translationKey = alertMessagesMapping[otpErrorMessage];

    return translationKey
      ? t(translationKey)
      : otpErrorMessage.response.data.description;
  };

  const getSubtitle = () => {
    if (availableMediums.length > 0) {
      if (
        availableMediums.includes(availableMediumsEnum.SMS) &&
        availableMediums.includes(availableMediumsEnum.EMAIL)
      ) {
        return t("otpVerificationWithEmailSubtitle");
      } else if (availableMediums.includes(availableMediumsEnum.SMS)) {
        return t("otpVerificationWithoutEmailSubtitle");
      }
    }
  };

  useEffect(() => {
    if (isVerifyMaxAttempts) {
      setRemainingTime(5 * otpCounter);
    }
  }, [isVerifyMaxAttempts]);

  useEffect(() => {
    if (!civilIDNumber) {
      navigate(civildIDetailsRenewalRoute);
    }
  }, []);

  return (
    <>
      <MainLayout
        title={t("enterYourVerificationCode")}
        subTitle={getSubtitle()}
        footer={renderFooter}
        withAlertMessage={resendOTPMessage || otpErrorMessage}
      >
        <div className={styles.container}>
          <InputField
            isDisabled={isFieldDisabled}
            label={t("otpNumberLabel")}
            onChange={handleChange}
            value={OTPInfo.value}
            errorMessage={OTPInfo.error}
            name={"optInput"}
          />
          {isLoading && (
            <div className={styles.loaderContainer}>
              <div className={styles.loader} />
            </div>
          )}
        </div>
      </MainLayout>
      {resendOTPMessage && (
        <Alert
          text={t("resendOTPMessage")}
          setText={setResendOTPMessage}
          leftIcon={infoIcon}
          rightIcon={closeIcon}
          styling={{ backGroundColor: "#D6F5F0", textColor: "#1BBAA4" }}
        />
      )}
      {otpErrorMessage && (
        <Alert
          text={getOtpErrorMessage()}
          setText={setOtpErrorMessage}
          leftIcon={errorInfoIcon}
          rightIcon={errorCloseIcon}
          styling={{ backGroundColor: "#ffedf0", textColor: "#e94a66" }}
        />
      )}
    </>
  );
};
export default OTPDetailsRenewal;
