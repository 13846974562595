import React from "react";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import { getPossibleIncomeSourceOptions } from "../helper";
import CustomMultiSelect from "../../../widgets/inputs/custom-multi-select";
import KYCValidator from "../../../helpers/validation";

const AnnualIncomeSource = ({ data, setKycData, kycFields, translator }) => {
  const fieldKey = KycKeys.INCOME_SOURCE_V2;
  const incomeTypeKey = KycKeys.INCOME_TYPE;
  const incomeDetailsKey = KycKeys.INCOME_DETAILS;
  const employmentStatusKey = KycKeys.EMPLOYMENT_STATUS;

  const { incomeSource } = kycFields;
  const {
    [fieldKey]: { value, error },
    [employmentStatusKey]: { value: employmentStatusValue },
  } = data;
  const incomeSourceOptions = getPossibleIncomeSourceOptions(
    incomeSource,
    employmentStatusValue
  );

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value,
      },
      [incomeTypeKey]: { ...prev[incomeTypeKey], error: "", value: "" },
      [incomeDetailsKey]: { ...prev[incomeDetailsKey], error: "", value: "" },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  return (
    <CustomMultiSelect
      name={"annualIncomeSource"}
      label={translator("sourceAnnualIncomeLabel")}
      onChange={handleChange}
      defaultValues={value}
      errorMessage={error}
      inputOptions={incomeSourceOptions}
    />
  );
};

AnnualIncomeSource.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};
export default AnnualIncomeSource;
