import React, { useEffect, useState } from "react";
import KYCValidator from "../../../helpers/validation";
import InputField from "../../../widgets/inputs/primary-input";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import { capitalizeWords } from "../../../utils";

const ChildFamilyName = ({
  data,
  setKycData,
  counter,
  translator,
  isRenewal = false,
  counterValue = 1,
}) => {
  const childrenDetailsKey = KycKeys.CHILDREN_DETAILS;
  const childrenNameKey = KycKeys.CHILDREN_NAME;
  const fieldKey = KycKeys.FAMILY_NAME;
  const [errorMessage, setErrorMessage] = useState("");

  const {
    [childrenDetailsKey]: childrenNameList,
    [childrenNameKey]: { name: childNameKey, [fieldKey]: value },
  } = data;
  const validateField = (value, childrensList) => {
    const fieldErrorObject = KYCValidator.validateNames(value);

    if (fieldErrorObject.errorMessage) {
      setErrorMessage(fieldErrorObject.errorMessage);
    } else {
      setErrorMessage("");
    }

    setKycData((prev) => ({
      ...prev,
      [childrenDetailsKey]: {
        ...prev[childrenDetailsKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value: childrensList,
      },
    }));
  };

  const handleChange = ({ target: { value } }) => {
    const updatedChildrenList = childrenNameList.map((item) =>
      item.name === childNameKey ? { ...item, [fieldKey]: value } : item
    );

    const existingItemIndex = updatedChildrenList.findIndex(
      (item) => item.name === childNameKey
    );
    if (existingItemIndex === -1) {
      updatedChildrenList.push({
        childNameKey,
        [fieldKey]: value,
      });
    }

    validateField(value, updatedChildrenList);
  };

  const handleBlur = ({ target: { value } }) => {
    const capitalizedFullName = capitalizeWords(value);

    const updatedChildrenList = childrenNameList.map((item) =>
      item.name === childNameKey
        ? { ...item, [fieldKey]: capitalizedFullName }
        : item
    );

    const existingItemIndex = updatedChildrenList.findIndex(
      (item) => item.name === childNameKey
    );

    if (existingItemIndex === -1) {
      updatedChildrenList.push({
        childNameKey,
        [fieldKey]: capitalizedFullName,
      });
    }

    setKycData((prev) => ({
      ...prev,
      [childrenDetailsKey]: {
        ...prev[childrenDetailsKey],
        isValidated: true,
        value: updatedChildrenList,
      },
    }));
  };

  useEffect(() => {
    if (isRenewal && counterValue == 1) {
      validateField(value, childrenNameList);
    } else if (isRenewal && counterValue !== 1) {
      setErrorMessage("");
    }
  }, [counterValue]);

  return (
    <InputField
      name={childNameKey + fieldKey}
      key={counter}
      label={translator("childFamilyName", { number: counter })}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      errorMessage={errorMessage}
    />
  );
};

ChildFamilyName.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  counter: PropTypes.number.isRequired,
};

export default ChildFamilyName;
