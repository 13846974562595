import React, { useEffect, useState } from "react";
import KYCValidator from "../../../helpers/validation";
import DatePicker from "../../../widgets/date-picker";
import KycKeys from "../../../enums/kyc-enum";
import moment from "moment";
import PropTypes from "prop-types";

const CivilIdExpiryDateRenewal = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.CIVIL_ID_EXPIRY_DATE;

  const {
    [fieldKey]: { value, error, isDisabled },
  } = data;

  const [date, setDate] = useState(
    value ? moment(value).format("DD/MM/YYYY") : ""
  );

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateCivilIdExpiryDate(value);
    const formattedDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD");
    const isDateValid = moment(formattedDate, "YYYY-MM-DD").isValid();
    setDate(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value: isDateValid ? formattedDate : "",
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  const validateFieldOnLoad = () => {
    const fieldErrorObject = KYCValidator.validateCivilIdExpiryDate(date);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
      },
    }));
  };

  return (
    <DatePicker
      name={"civilIdExpiryDate"}
      label={translator("civilIdExpiryDate")}
      onChange={handleChange}
      value={date}
      error={error}
      isDisabled={isDisabled}
    />
  );
};

CivilIdExpiryDateRenewal.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};

export default CivilIdExpiryDateRenewal;
