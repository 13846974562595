import React, { useEffect } from "react";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import {
  getIsWealthOfSourceValueDisabled,
  getDefaultWealthSourceDefaultValues,
  getWealthSourceValue,
} from "../helper";
import CustomMultiSelect from "../../../widgets/inputs/custom-multi-select";
import {
  incomeSourceEnums,
  wealthSourceEnums,
} from "../../../enums/income-and-wealth";
import KYCValidator from "../../../helpers/validation";

const WealthSource = ({ data, setKycData, kycFields, translator }) => {
  const fieldKey = KycKeys.WEALTH_SOURCE;
  const incomeSourceKey = KycKeys.INCOME_SOURCE_V2;
  const wealthAmountInheritanceKey = KycKeys.WEALTH_AMOUNT_INHERITANCE;
  const wealthDetailsInheritanceKey = KycKeys.WEALTH_DETAILS_INHERITANCE;
  const wealthAmountInvestmentsKey = KycKeys.WEALTH_AMOUNT_INVESTMENTS;
  const wealthDetailsInvestmentsKey = KycKeys.WEALTH_DETAILS_INVESTMENTS;
  const wealthAmountJob = KycKeys.WEALTH_AMOUNT_JOB;
  const wealthAmountSavingKey = KycKeys.WEALTH_AMOUNT_SAVING;
  const wealthDetailsSavingKey = KycKeys.WEALTH_DETAILS_SAVING;
  const wealthAmountSaleKey = KycKeys.WEALTH_AMOUNT_SALE;
  const wealthDetailsSaleKey = KycKeys.WEALTH_DETAILS_SALE;
  const wealthAmountOtherKey = KycKeys.WEALTH_AMOUNT_OTHER;
  const wealthDetailsOtherKey = KycKeys.WEALTH_DETAILS_OTHER;

  const { wealthSources } = kycFields;
  const {
    [fieldKey]: { value, error },
    [incomeSourceKey]: { value: incomeSourceValue },
  } = data;

  const hasInvestment = incomeSourceValue.some(
    (item) => item === incomeSourceEnums.INVESTMENT
  );
  const hasPensionSocialSecurity = incomeSourceValue.some(
    (item) => item === incomeSourceEnums.PENSION_SOCIAL_SECURITY
  );

  const wealthSourceValues = value.map((val) => ({
    label: wealthSources[val],
    value: val,
  }));
  const wealthSourceOptions = Object.keys(wealthSources).map((key) => ({
    label: wealthSources[key],
    value: key,
    isDisabled: getIsWealthOfSourceValueDisabled(key, hasInvestment),
  }));

  const validateField = (value) => {
    const wealthSources = [
      { key: wealthAmountInheritanceKey, enum: wealthSourceEnums.INHERITANCE },
      { key: wealthDetailsInheritanceKey, enum: wealthSourceEnums.INHERITANCE },
      { key: wealthAmountInvestmentsKey, enum: wealthSourceEnums.INVESTMENT },
      { key: wealthDetailsInvestmentsKey, enum: wealthSourceEnums.INVESTMENT },
      { key: wealthAmountJob, enum: wealthSourceEnums.JOB_INDEMNITY },
      { key: wealthAmountSavingKey, enum: wealthSourceEnums.SAVING },
      { key: wealthDetailsSavingKey, enum: wealthSourceEnums.SAVING },
      { key: wealthAmountSaleKey, enum: wealthSourceEnums.SALE_OF_PROPERTY },
      { key: wealthDetailsSaleKey, enum: wealthSourceEnums.SALE_OF_PROPERTY },
      { key: wealthAmountOtherKey, enum: wealthSourceEnums.OTHER },
      { key: wealthDetailsOtherKey, enum: wealthSourceEnums.OTHER },
    ];

    const fieldErrorObject = KYCValidator.validateField(value);

    setKycData((prev) => {
      const updatedData = wealthSources.reduce((acc, source) => {
        const isVisible = value.some((key) => key === source.enum);
        acc[source.key] = {
          ...prev[source.key],
          error: isVisible ? fieldErrorObject.errorMessage : "",
          value: isVisible ? prev[source.key].value : "",
          isVisible,
        };
        return acc;
      }, {});

      return {
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          error: fieldErrorObject.errorMessage,
          value,
        },
        ...updatedData,
      };
    });
  };

  const handleChange = (value) => {
    validateField(value);
  };

  const handleIncomeSourceChange = (hasInvestment) => {
    let changedValue = [...wealthSourceValues];

    if (!hasInvestment) {
      changedValue = changedValue.filter(
        (item) => item.value !== wealthSourceEnums.INVESTMENT
      );
    }

    return changedValue;
  };

  const checkDefaultValue = () => {
    const defaultValue = getDefaultWealthSourceDefaultValues(
      wealthSources,
      hasInvestment
    );

    const filteredValue = handleIncomeSourceChange(hasInvestment);

    if (defaultValue.length > 0) {
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          error: "",
          value: [
            ...defaultValue.map((option) => option.value),
            ...getWealthSourceValue(filteredValue, defaultValue),
          ],
        },
      }));
    }
  };

  useEffect(() => {
    checkDefaultValue();
  }, [incomeSourceValue]);

  return (
    <CustomMultiSelect
      name={"wealthSource"}
      label={translator("sourceOfWealthLabel")}
      onChange={handleChange}
      defaultValues={value}
      errorMessage={error}
      inputOptions={wealthSourceOptions}
    />
  );
};

WealthSource.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};
export default WealthSource;
